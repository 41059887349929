import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root',
})
export class LanguageChangerService {
    private _functions: Map<string, any> = new Map();

    constructor(private _transLoco: TranslocoService) {
        this._transLoco.langChanges$.subscribe(lang => {
            this.handleCalls();
        });
    }

    private handleCalls(): void {
        this._functions.forEach((arrowFunction, key) => {
            try {
                arrowFunction();
            } catch (error) {
                console.error('Error executing function with key:', key, error);
            }
        });
    }

    public register(name: string, arrowFunction: any): void {
        this._functions.set(name, arrowFunction);
    }

    public unregister(name: string): void {
        this._functions.delete(name);
    }

    public get currentLanguage(): string {
        return this._transLoco.getActiveLang();
    }
}
