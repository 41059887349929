import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core';
import { TreeNode } from '@em4cloud/tree-view';
import { TranslocoService } from '@ngneat/transloco';
import { ObjExplorerService, ObjectExplorerItem, ObjectExplorerItemType } from 'app/api';
import { LanguageChangerService } from 'app/services/translate_api/languageChanger.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ObjectExplorerService {
    objectExplorerItems: WritableSignal<ObjectExplorerItem[]> = signal([]);
    treeNodes: Signal<TreeNode[]> = computed(() => {
        return this.transformObjectExplorerItemIntoTreeNode(this.objectExplorerItems());
    });

    constructor(
        private service: ObjExplorerService,
        private languageChangeService: LanguageChangerService,
        private translocoService: TranslocoService
    ) {
        this.getObjExplorerItems();
        this.languageChangeService.register(
            'objectExplorerItems',
            this.getObjExplorerItems.bind(this)
        );
    }

    applyTranslations(backendObject: ObjectExplorerItem, translations: any): ObjectExplorerItem {
        const translatedObject: ObjectExplorerItem = { ...backendObject };

        // Translate the simple properties like `name`
        if (translatedObject.name && translations.name) {
            translatedObject.name = translations.name;
        }

        // Translate the `infoTypes` array if it exists
        if (translatedObject.infoTypes && Array.isArray(translatedObject.infoTypes)) {
            translatedObject.infoTypes = translatedObject.infoTypes.map(infoType => {
                if (translations.infoTypes && translations.infoTypes[infoType.id]) {
                    const translatedFields = infoType.fields.map(field => ({
                        ...field,
                        label:
                            translations.infoTypes[infoType.id][field.propertyName].label ||
                            field.label,
                        placeholder:
                            translations.infoTypes[infoType.id][field.propertyName].placeholder ||
                            field.placeholder,
                    }));
                    return { ...infoType, fields: translatedFields };
                }
                return infoType;
            });
        }

        // Translate the `metadataDescriptor` if it exists
        if (translatedObject.metadataDescriptor?.fields?.length) {
            const mdId = 'mdId';

            if (translations.metadataDescriptor && translations.metadataDescriptor[mdId]) {
                translatedObject.metadataDescriptor.fields =
                    translatedObject.metadataDescriptor.fields.map(field => ({
                        ...field,
                        label:
                            translations.metadataDescriptor[mdId][field.propertyName].label ||
                            field.label,
                        placeholder:
                            translations.metadataDescriptor[mdId][field.propertyName].placeholder ||
                            field.placeholder,
                    }));
            }
        }
        return translatedObject;
    }

    async getObjExplorerItems(language?: string): Promise<void> {
        let items = await lastValueFrom(this.service.getObjectExplorerItems());

        items = items.map(item => {
            if (!item.metadata) {
                item.metadata = {};
            }

            const translations = item.metadata?.['translations'];

            if (translations) {
                const currentLanguage = language || this.languageChangeService.currentLanguage;
                let currentTranslation = translations[currentLanguage];

                if (!currentTranslation) {
                    console.info("No language provided, defaulting to 'en'");
                    currentTranslation = translations['en'];
                    currentTranslation = this.setTranslatedName(
                        item,
                        currentLanguage,
                        currentTranslation
                    );
                }
                if (!currentTranslation.name) {
                    currentTranslation = this.setTranslatedName(
                        item,
                        currentLanguage,
                        currentTranslation
                    );
                }
                item = this.applyTranslations(item, currentTranslation);
            } else {
                item.metadata['translations'] = {
                    en: {
                        name: item.name,
                    },
                };

                item = this.applyTranslations(item, {
                    name: this.translocoService.translate(
                        `objExplorerNames.${item.name.toLowerCase()}`
                    ),
                });
            }
            return item;
        });
        this.objectExplorerItems.set(items);
    }

    private setTranslatedName(item: any, currentLanguage: string, currentTranslation: any): any {
        item.metadata['translations'].en['name'] = item.name;
        const translatedName = this.translocoService.translate(
            `objExplorerNames.${item.name.toLowerCase()}`
        );

        currentTranslation.name = translatedName;
        return currentTranslation;
    }

    async updateObjExplorerItem(
        objectExplorerItem: ObjectExplorerItem,
        language?: string
    ): Promise<void> {
        objectExplorerItem.name = objectExplorerItem.metadata?.['translations'].en.name;

        await lastValueFrom(this.service.updateObjectExplorerItem(objectExplorerItem));
        await this.getObjExplorerItems(language);
    }

    async addObjectExplorerItem(objectExplorerItem: ObjectExplorerItem): Promise<void> {
        await lastValueFrom(this.service.createObjectExplorerItem(objectExplorerItem));
        await this.getObjExplorerItems();
    }

    transformObjectExplorerItemIntoTreeNode(items: ObjectExplorerItem[]): TreeNode[] {
        const parents = Object.values(ObjectExplorerItemType);

        const treeNodes: TreeNode[] = [];

        parents.forEach((parent, index) => {
            treeNodes.push({
                id: parent,
                title: Object.keys(ObjectExplorerItemType)[index],
                children: [],
            });
        });

        items.forEach(item => {
            const parent = item.type;
            treeNodes
                .find(treeNode => treeNode.id === parent)
                .children.push({
                    id: item.id,
                    title: item.name,
                    meta: {
                        type: item.type,
                        status: item.status,
                    },
                    children: [],
                });
        });

        return treeNodes;
    }
}
