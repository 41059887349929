import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseAlertService } from '@fuse/components/alert';
import { TranslocoService } from '@ngneat/transloco';
import { AlertData } from './alert.types';
import { AlertComponent } from './alert/alert.component';

@Injectable({ providedIn: 'root' })
export class AlertService extends FuseAlertService {
    constructor(
        private _snackbar: MatSnackBar,
        private _translocoService: TranslocoService,
        private _injector: Injector
    ) {
        super();
    }

    /**
     *
     * @param messageKey The key to get from the transloco file
     * @param scope The scope to get in transloco
     */
    successAlert(scope: string, messageKey: string, title?: string, duration?: number) {
        let data: AlertData = {
            showIcon: true,
            type: 'success',
            appearance: 'fill',
            dismissible: true,
            content: this.getTranslation(messageKey, scope),
            title: this.getTranslation(title, scope),
            duration: duration,
        };
        this.showAlert(data);
    }

    /**
     *
     * @param messageKey The key to get from the transloco file
     * @param scope The scope to get in transloco
     */
    errorAlert(scope: string, messageKey: string, title?: string) {
        let data: AlertData = {
            showIcon: true,
            type: 'error',
            appearance: 'fill',
            dismissible: true,
            content: this.getTranslation(messageKey, scope),
            title: this.getTranslation(title, scope),
        };
        this.showAlert(data);
    }

    /**
     *
     * @param messageKey The key to get from the transloco file
     * @param scope The scope to get in transloco
     */
    basicAlert(scope: string, messageKey: string, title?: string) {
        let data: AlertData = {
            showIcon: true,
            type: 'basic',
            appearance: 'fill',
            dismissible: true,
            content: this.getTranslation(messageKey, scope),
            title: this.getTranslation(title, scope),
        };
        this.showAlert(data);
    }

    infoAlert(scope: string, messageKey: string, title?: string) {
        let data: AlertData = {
            showIcon: true,
            type: 'info',
            appearance: 'fill',
            dismissible: true,
            content: this.getTranslation(messageKey, scope),
            title: this.getTranslation(title, scope),
        };
        this.showAlert(data);
    }

    /**
     *
     * @param key The key to get from the transloco file
     * @param scope The scope to get in transloco
     * @returns The translated string
     */
    getTranslation(key: string, scope: string): string {
        const translation = this._translocoService.getTranslation(scope === '' ? null : scope)[key];
        return translation;
    }

    /**
     * Method to show alert in the UI
     *
     * @param data Alert data and config
     */
    showAlert(data: AlertData) {
        this._snackbar.openFromComponent<AlertComponent, AlertData>(AlertComponent, {
            duration: data.duration ? data.duration : 5000,
            data: data,
        });
    }
}
