<fuse-alert
    [appearance]="appearance"
    [dismissed]="dismissed"
    [dismissible]="dismissible"
    [name]="name"
    [showIcon]="showIcon"
    [type]="type"
    (dismissedChanged)="dismissedChangedEvent($event)">
    @if (data.title) {
    <div fuseAlertTitle> {{ data.title }} </div>
    } @if (data.content) {
    <div> {{ data.content }} </div>
    }
</fuse-alert>
