import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FuseAlertComponent } from '@fuse/components/alert';
import { AlertService } from '../alert.service';
import { FuseUtilsService } from '@fuse/services/utils';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AlertData } from '../alert.types';

@Component({
    selector: 'alert',
    standalone: true,
    imports: [FuseAlertComponent],
    templateUrl: './alert.component.html',
    styleUrl: './alert.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AlertComponent extends FuseAlertComponent implements OnInit, OnDestroy {
    /**
     * Constructor
     */
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: AlertData,
        private changeDetectorRef: ChangeDetectorRef,
        private alertService: AlertService,
        private fuseUtilsService: FuseUtilsService
    ) {
        super(changeDetectorRef, alertService, fuseUtilsService);
        if (data.appearance) this.appearance = data.appearance;
        this.dismissed = data.dismissed;
        this.dismissible = data.dismissible;
        if (data.name) this.name = data.name;
        this.showIcon = data.showIcon;
        if (data.type) this.type = data.type;
    }

    /**
     * On init
     */
    ngOnInit(): void {
        super.ngOnInit();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    dismissedChangedEvent($event: boolean) {
        this.dismissedChanged.emit($event);
    }
}
